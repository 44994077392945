header{
    z-index: 99999999999 !important;
    a{
        cursor: pointer ;
    }
    .MuiContainer-root.container-full-width{
        max-width: 100% !important;
        padding: 0 40px;
    }
    .mobile-logo{
        @media(max-width: 768px){
            display: block !important;
            max-width: 40px;
        }
    }
    .mobile-logo-text{
        font-size: 20px;
    }
    
    
@media(min-width: 768px){
    .menu-item{
        border-radius: 0;
        border-right: 1px solid #000;
        padding: 10px 30px;
        margin: 0;
        height: 100%;
        display: block;
        letter-spacing: 2px;
        font-weight: 600;
        &:last-child{
            border: none;
            padding-right: 0;
        }
    }

}
}

.nav-links{
    letter-spacing: 2px;
}

.navbar {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999999999;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    position: relative;
  }
  
  .nav-logo {
    color: #000;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    
    display: flex;
    align-items: center;
    justify-items: center;
  }
  .nav-logo  .logo-text{
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 30px;
    font-weight: normal;
    color: map-get($map: $theme-color-primary, $key:dark );
  }
  .nav-logo .icon {
    display: inline-block;
    width: 60px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    margin-bottom: 0;
  }
  
  .nav-links {
    color: map-get($theme-color-primary, "dark") ;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    font-weight: 400;
    &:hover{
        color: #262626;
        text-decoration: none;
    }
    &.active {
        font-weight: 500;
    }
  }
  .nav-icon {
    display: none;
    color: map-get($map: $theme-color-primary, $key: dark);
  }
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  
  .nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
  @media screen and (max-width: 1200px) and (min-width: 961px){
    .nav-links{
      padding: 0.5rem 0.5rem;
      font-size: 12px;
    }
    .nav-logo .logo-text{
      font-size: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 100%;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 50px;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }
    .nav-logo  .logo-text{
      font-size: 16px;
    }
  }