.btn-outline-white{
    color: #fff;
    display: inline-block;
    position: relative;
    border: 1px solid #fff !important;
    text-decoration: none;
    padding: 10px 20px;
    font-weight: 500;
    letter-spacing: 1px;
    background: #0005;
    &:hover{
        background: #fff;
        border: 1px solid #000;
        color: #000;
    }
}

.btn-outline-dark{
    color: map-get($theme-color-primary, "dark");
    display: inline-block;
    position: relative;
    border: 1px solid map-get($theme-color-primary, "dark") !important;
    text-decoration: none;
    padding: 10px 20px;
    font-weight: 500;
    letter-spacing: 1px;
    background: #fff;
    &:hover{
        background: map-get($map: $theme-color-primary, $key: dark);
        border: 1px solid map-get($map: $theme-color-primary, $key: dark) !important;
        color: #fff;
    }
}